import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { CircleNotch } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import SearchInput from "common/components/SearchInput/SearchInput";

import { Conversation } from "../types";
import ConversationsList from "./ConversationsList/ConversationsList";
import ConversationContainer from "./ConversationContainer/ConversationContainer";

import SendMessageImage from "assets/images/empty-placeholders/send-a-message.png";
import NoContactsImage from "assets/images/empty-placeholders/no-contacts.png";

interface DesktopInboxProps {
  activeConversation: Conversation;
  activeConversationUrn: string;
  setActiveConversationUrn: (conversationUrn: string) => void;
  conversations: Conversation[];
  loadMoreConversations: () => void;
  keywordsInputValue: string;
  setKeywordsInputValue: (keywords: string) => void;
  keywords: string;
  clearKeywords: () => void;
  isLoadingConversations: boolean;
  hasMoreConversations: boolean;
}

export default function DesktopInbox({
  activeConversation,
  activeConversationUrn,
  setActiveConversationUrn,
  conversations,
  loadMoreConversations,
  keywordsInputValue,
  setKeywordsInputValue,
  keywords,
  clearKeywords,
  isLoadingConversations,
  hasMoreConversations,
}: DesktopInboxProps) {
  const isConversationsEmpty =
    !isLoadingConversations &&
    !conversations.length &&
    !keywords &&
    !activeConversation &&
    !activeConversationUrn;

  if (isConversationsEmpty) {
    return (
      <EmptyPlaceholder
        imageSrc={NoContactsImage}
        title="No contacts Added yet"
        subtitle="When you connect with someone they will show up here"
        actionComponent={
          <Button asChild>
            <Link to="../campaigns">Go to Campaigns</Link>
          </Button>
        }
      />
    );
  }

  return (
    <section className="relative flex grow items-stretch md:h-[calc(100vh-8.125rem)]">
      <div className="mr-4 flex w-full max-w-md flex-col">
        {/* Input search for filtering conversations list */}
        <SearchInput
          placeholder="Search"
          className="fs-mask mb-4"
          variant="lg"
          value={keywordsInputValue}
          onChange={(e) => setKeywordsInputValue(e.target.value)}
          onClear={() => setKeywordsInputValue("")}
          rightComponent={
            keywords &&
            isLoadingConversations && (
              <CircleNotch className="animate-spin text-black-500" />
            )
          }
        />

        {/* Conversations list preview sidebar */}
        <div
          id="conversation-list-scroll-container"
          className="flex h-full flex-col overflow-y-auto scrollbar-thin [mask-image:_linear-gradient(to_bottom,_black_128px,_black_calc(100%-100px),transparent_100%)]"
        >
          <ConversationsList
            loadMoreConversations={loadMoreConversations}
            isLoadingConversations={isLoadingConversations}
            conversations={conversations}
            activeConversation={activeConversation}
            setActiveConversationUrn={setActiveConversationUrn}
            hasMoreConversations={hasMoreConversations}
            keywords={keywordsInputValue}
            clearKeywords={clearKeywords}
          />
        </div>
      </div>

      {/* Selected conversation details */}
      <div
        className={clsx([
          "relative flex w-full flex-col overflow-hidden rounded-20",
          activeConversation && !isLoadingConversations
            ? "bg-black-50"
            : "border border-dashed border-black-300 bg-white",
        ])}
      >
        {activeConversation ? (
          <ConversationContainer
            activeConversation={activeConversation}
            isLoadingConversations={isLoadingConversations}
          />
        ) : (
          <EmptyPlaceholder
            imageSrc={SendMessageImage}
            title="Select a conversation"
            subtitle="Chat with your leads directly through Aimfox"
          />
        )}
      </div>
    </section>
  );
}
