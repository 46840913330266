import React from "react";
import clsx from "clsx";

import { useSelectedSubscriptionContext } from "common/helpers/SelectedSubscriptionContext";

import { Conversation } from "../../types";
import MessageDetails from "./MessageDetails";
import TimestampDisplay from "./TimestampDisplay";
import UnreadCount from "./UnreadCount";
import ParticipantInfo from "../ParticipantInfo";

interface ConversationItemProps {
  conversation: Conversation;
  onSelect: () => void;
  isLoading: boolean;
  isSelected: boolean;
}

export default function ConversationItem({
  conversation,
  onSelect,
  isLoading,
  isSelected,
}: ConversationItemProps) {
  const { account } = useSelectedSubscriptionContext();
  const { last_message: lastMessage, unread_count: unreadCount } = conversation;

  const isMine = Number(account?.id) === lastMessage.sender?.id;

  return (
    <>
      <button
        type="button"
        className={clsx(
          "my-1 flex flex-col gap-4 rounded-2xl p-4 transition-colors duration-100 ease-linear last:mb-0",
          isSelected && "bg-black-50 shadow-sm",
        )}
        disabled={isLoading}
        onClick={onSelect}
        aria-label={`Conversation with ${conversation.participants[0].full_name}`}
      >
        <div className="flex w-full flex-row items-center justify-between">
          {/* Participant details (name, avatar, occupation) */}
          <ParticipantInfo conversation={conversation} />

          {/* Mark as unread popover */}
          {/* TODO: Implement this later when endpoint for unread is added */}
          {/* <Popover
            open={isPopoverOpen}
            onOpenChange={(open) => setIsPopoverOpen(open)}
            modal
          >
            <PopoverTrigger onClick={(e) => e.stopPropagation()} asChild>
              <Button intent="iconOnly" size="sm" variant="quaternary-black">
                <DotsThree />
              </Button>
            </PopoverTrigger>

            <PopoverContent>
              <button
                type="button"
                className="flex w-full flex-row items-center gap-2 bg-white p-4 hover:bg-black-50"
                onClick={markAsUnread}
              >
                <ChatDots size={20} />
                <span className="text-button-14">Mark as unread</span>
              </button>
            </PopoverContent>
          </Popover> */}
        </div>

        {/* Last message from the conversation */}
        <MessageDetails conversation={conversation} isMine={isMine} />

        {/* Timestamp details */}
        <div className="flex w-full flex-row items-center justify-between">
          <TimestampDisplay createdAt={lastMessage.created_at} />
          <UnreadCount unreadCount={unreadCount} />
        </div>
      </button>

      {/* Separator line */}
      <div className="h-px w-full bg-black-100 last:hidden" />
    </>
  );
}
